import React from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import { css } from "glamor";
import g from "glamorous";
import { Container, Row, Col } from "glamorous-grid";
import Layout from "../components/layout";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import IndicationsBlock from "../components/IndicationsBlock";
import { FaHome } from "react-icons/fa";

const mediaQueries = {
  phone: "@media only screen and (max-width: 991px)",
};

export default function Template() {
  const Div = styled.div`
    margin: 0 auto;
    background-color: #fcfcfc;
  `;

  const WhiteRow = g.section({
    backgroundColor: `white`,
    padding: `2em 0`,
    borderBottom: `1px solid #e9eaeb`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  const H1 = g.h1({
    fontSize: `3em`,
    letterSpacing: `.08em`,
    fontWeight: `600`,
    marginTop: `2em`,
    color: `#3c4d68`,
    margin: `10px 0 .3em`,
    cursor: `default`,
    [mediaQueries.phone]: {
      fontSize: `2.4em`,
    },
  });

  const Subtitle = g.div({
    color: `#3c4d68`,
    fontWeight: `600`,
    fontSize: `1.1em`,
    letterSpacing: `.03em`,
    marginBottom: `.5em`,
    cursor: `default`,
  });

  const Trier = styled.span`
    @media (max-width: 678px) {
      font-size: 0.75em;
      display: block;
      text-align: center;
    }
  `;

  const Selector = styled.div`
    display: inline;
    padding-left: 5px;
    @media (max-width: 678px) {
      display: block;
      padding-left: 0px;
      font-size: 0.9em;
      text-align: center;
      font-weight: 700;
      letter-spacing: -0.01em;
      margin-top: -3px;
    }
  `;

  const StyledLink = styled(Link)`
    color: #3c4d68;
    opacity: 0.55;
    :hover {
      opacity: 1;
    }
    position: relative;
    text-decoration: none;
    ::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      border-radius: 4px;
      background-color: #3c4d68;
      bottom: -5px;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
    :hover::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  `;

  return (
    <Layout>
      <Global />
      <Div id="page-wrap">
        <Helmet>
          <title>
            Traitements esthétiques par indications | Dr Cédric Kron
          </title>
          <meta
            name="description"
            content="Découvrez les traitements esthétiques adaptés à vos attentes : restaurer les volumes, supprimer les rides, perdre de la masse graisseuse, redessiner la silhouette."
          />
          <html lang="fr" />
          <link
            rel="alternate"
            hreflang="en"
            href="https://www.dr-kron.com/en/treatments/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Vos besoins",
          "item": "https://www.dr-kron.com/indications/"
          }]
              }
    `}</script>

          <link rel="preload" as="image" href="/trop-petite.jpg" />
          <link rel="preload" as="image" href="/cernes.jpg" />
          <link rel="preload" as="image" href="/cicatrices.jpg" />
          <link rel="preload" as="image" href="/effacer-rides.jpg" />
          <link rel="preload" as="image" href="/forme-nez.jpg" />
          <link rel="preload" as="image" href="/jambes.jpg" />
          <link rel="preload" as="image" href="/lobes.jpg" />
          <link rel="preload" as="image" href="/modifier-forme-paupieres.jpg" />
          <link rel="preload" as="image" href="/nez.jpg" />
          <link rel="preload" as="image" href="/oreilles.jpg" />
          <link rel="preload" as="image" href="/peau.jpg" />
          <link rel="preload" as="image" href="/peeling-biorevitalisant.jpg" />
          <link rel="preload" as="image" href="/perdre-masse-graisseuse.jpg" />
          <link rel="preload" as="image" href="/rajeunir-peau.jpg" />
          <link rel="preload" as="image" href="/reconstruction.jpg" />
          <link rel="preload" as="image" href="/redessiner-sa-silhouette.jpg" />
          <link rel="preload" as="image" href="/regard.jpg" />
          <link rel="preload" as="image" href="/relachee.jpg" />
          <link rel="preload" as="image" href="/respirer.jpg" />
          <link rel="preload" as="image" href="/restaurer-volumes.jpg" />
          <link rel="preload" as="image" href="/rides.jpg" />
          <link rel="preload" as="image" href="/seins.jpg" />
          <link rel="preload" as="image" href="/silhouette.jpg" />
          <link
            rel="preload"
            as="image"
            href="/supprimner-bajoues-vignettes.jpg"
          />
          <link rel="preload" as="image" href="/taches.jpg" />
          <link rel="preload" as="image" href="/terne.jpg" />
          <link rel="preload" as="image" href="/tiers-superieur.jpg" />
          <link rel="preload" as="image" href="/tombante.jpg" />
          <link rel="preload" as="image" href="/cellulite.jpg" />
          <link rel="preload" as="image" href="/affiner-jambes.jpg" />
          <link rel="preload" as="image" href="/oreilles-decollees.jpg" />
          <link rel="preload" as="image" href="/visage.jpg" />
          <link rel="preload" as="image" href="/sculpter-jambes.jpg" />
        </Helmet>
        <Menu current="interventions" pathEn="/en/treatments/" />
        <main>
          <WhiteRow css={{ paddingBottom: `8px !important` }}>
            <Container>
              <Row>
                <Col>
                  <Breadcrumb
                    css={{
                      marginTop: `3em !important`,
                      "@media only screen and (max-width: 600px)": {
                        display: `none`,
                      },
                    }}
                  >
                    <BreadcrumbItem>
                      <Link
                        to="/"
                        {...cssBreadcrumbLink}
                        title="Page d'accueil"
                      >
                        <FaHome
                          css={{
                            fontSize: `1.2em`,
                            paddingBottom: `.2em`,
                            transform: `translateY(5px)`,
                          }}
                        />{" "}
                        Accueil
                      </Link>
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <H1>Interventions</H1>
                  <Subtitle>
                    <Trier>Trier par :</Trier>
                    <Selector>
                      Indications |&nbsp;
                      <StyledLink to="/interventions/">
                        Régions du corps
                      </StyledLink>
                      &nbsp;|&nbsp;
                      <StyledLink to="/techniques/">Techniques</StyledLink>
                    </Selector>
                  </Subtitle>
                </Col>
              </Row>
            </Container>
            <IndicationsBlock />
          </WhiteRow>
        </main>
        <Footer />
      </Div>
    </Layout>
  );
}
